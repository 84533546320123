/* eslint-disable react/jsx-key */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { graphql, navigate } from 'gatsby';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import { G_RECAPTCHA_PUBLIC_KEY } from '../constants/common';
import Layout from '../components/shared/layout';
import SEO from '../components/shared/seo';
import { postContactData } from '../api/htg-api';

const ContactPage = props => {
  const { data, location } = props;
  const siteTitle = data.site.siteMetadata.title;
  const [email, setEmail] = React.useState(undefined);
  const [body, setBody] = React.useState(undefined);
  const [captchaValue, setCaptchaValue] = React.useState(undefined);
  const [touchedBody, setTouchedBody] = React.useState(false);

  const handleSubmit = () => {
    if (!body) {
      setTouchedBody(true);
    } else {
      postContactData(email, body, captchaValue);
      navigate('/');
    }
  };

  const verifiedCaptcha = e => {
    setCaptchaValue(e);
  };

  return (
    <Layout>
      <SEO title="Contact Page" path={location.pathname} description="Contact page for Hacking the Grow" />
      <div className="text-2xl my-12 font-light">Contact Us</div>
      <form className="bg-htgGreen-light p-8 rounded-md flex flex-col space-y-4 shadow-md items-center">
        <div className="pr-4 flex items-center font-light">Email</div>
        <input
          type="text"
          className="border border-htgGreen-dark rounded-md p-2 w-full"
          placeholder="Email - optional"
          value={email}
          onChange={event => setEmail(event.target.value)}
        />
        <div className="pr-4 flex items-center font-light">Comments</div>
        <textarea
          type="text"
          className={`border rounded-md p-2 w-full resize-y ${
            touchedBody && !body ? 'border-red-600' : 'border-htgGreen-dark'
          }`}
          placeholder={
            touchedBody && !body ? 'Comments are required!' : 'Comments'
          }
          value={body}
          onChange={event => {
            setBody(event.target.value);
            setTouchedBody(true);
          }}
        />
        <ReCAPTCHA
          sitekey={G_RECAPTCHA_PUBLIC_KEY}
          onChange={verifiedCaptcha}
        />

        <button
          type="button"
          className={`w-1/2 px-4 py-2 ${
            !captchaValue
              ? 'bg-htgGreen cursor-not-allowed'
              : 'bg-htgGreen-light hover:bg-htgGreen-dark'
          } rounded-md`}
          disabled={!captchaValue}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </form>
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.string.isRequired
};

export default ContactPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
