/* eslint-disable import/prefer-default-export */
import { CONTACT_ENDPOINT } from '../constants/common';

export const postContactData = async (email, comment, captcha = undefined) => {
  try {
    const res = await fetch(CONTACT_ENDPOINT, {
      method: 'POST',
      // headers: {
      //   'Content-Type': 'application/json'
      // },
      body: JSON.stringify({ email, comment, captcha })
    });
    if (res.status === 200) {
      return res;
    }

    return undefined;
  } catch (error) {
    console.log('An error occurred POSTING the contact form');
    return undefined;
  }
};
